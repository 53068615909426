
@font-face {
  font-family:"Octicons";
  src:url(/static/media/octicons.d038ccbc.eot);
  src:url(/static/media/octicons.d038ccbc.eot?#iefix) format("embedded-opentype"),
		url(/static/media/octicons.de59a972.woff2) format("woff2"),
		url(/static/media/octicons.ee5b1bee.woff) format("woff"),
		url(/static/media/octicons.e0d4a324.ttf) format("truetype"),
		url(/static/media/octicons.190e7257.svg#octicons) format("svg");
  font-weight:normal;
  font-style:normal;
}


/*

.octicon is optimized for 16px.
.mega-octicon is optimized for 32px but can be used larger.

*/
.octicon, .mega-octicon {
  font: normal normal normal 16px/1 Octicons;
  display: inline-block;
  text-decoration: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
       user-select: none;
  speak: none;
}
.mega-octicon { font-size: 32px; }

.octicon-alert:before { content:"\F02D"; }

.octicon-arrow-down:before { content:"\F03F"; }

.octicon-arrow-left:before { content:"\F040"; }

.octicon-arrow-right:before { content:"\F03E"; }

.octicon-arrow-small-down:before { content:"\F0A0"; }

.octicon-arrow-small-left:before { content:"\F0A1"; }

.octicon-arrow-small-right:before { content:"\F071"; }

.octicon-arrow-small-up:before { content:"\F09F"; }

.octicon-arrow-up:before { content:"\F03D"; }

.octicon-beaker:before { content:"\F0DD"; }

.octicon-bell:before { content:"\F0DE"; }

.octicon-bold:before { content:"\F0E2"; }

.octicon-book:before { content:"\F007"; }

.octicon-bookmark:before { content:"\F07B"; }

.octicon-briefcase:before { content:"\F0D3"; }

.octicon-broadcast:before { content:"\F048"; }

.octicon-browser:before { content:"\F0C5"; }

.octicon-bug:before { content:"\F091"; }

.octicon-calendar:before { content:"\F068"; }

.octicon-check:before { content:"\F03A"; }

.octicon-checklist:before { content:"\F076"; }

.octicon-chevron-down:before { content:"\F0A3"; }

.octicon-chevron-left:before { content:"\F0A4"; }

.octicon-chevron-right:before { content:"\F078"; }

.octicon-chevron-up:before { content:"\F0A2"; }

.octicon-circle-slash:before { content:"\F084"; }

.octicon-circuit-board:before { content:"\F0D6"; }

.octicon-clippy:before { content:"\F035"; }

.octicon-clock:before { content:"\F046"; }

.octicon-cloud-download:before { content:"\F00B"; }

.octicon-cloud-upload:before { content:"\F00C"; }

.octicon-code:before { content:"\F05F"; }

.octicon-comment-discussion:before { content:"\F04F"; }

.octicon-comment:before { content:"\F02B"; }

.octicon-credit-card:before { content:"\F045"; }

.octicon-dash:before { content:"\F0CA"; }

.octicon-dashboard:before { content:"\F07D"; }

.octicon-database:before { content:"\F096"; }

.octicon-desktop-download:before { content:"\F0DC"; }

.octicon-device-camera-video:before { content:"\F057"; }

.octicon-device-camera:before { content:"\F056"; }

.octicon-device-desktop:before { content:"\F27C"; }

.octicon-device-mobile:before { content:"\F038"; }

.octicon-diff-added:before { content:"\F06B"; }

.octicon-diff-ignored:before { content:"\F099"; }

.octicon-diff-modified:before { content:"\F06D"; }

.octicon-diff-removed:before { content:"\F06C"; }

.octicon-diff-renamed:before { content:"\F06E"; }

.octicon-diff:before { content:"\F04D"; }

.octicon-ellipses:before { content:"\F101"; }

.octicon-ellipsis:before { content:"\F09A"; }

.octicon-eye:before { content:"\F04E"; }

.octicon-file-binary:before { content:"\F094"; }

.octicon-file-code:before { content:"\F010"; }

.octicon-file-directory:before { content:"\F016"; }

.octicon-file-media:before { content:"\F012"; }

.octicon-file-pdf:before { content:"\F014"; }

.octicon-file-submodule:before { content:"\F017"; }

.octicon-file-symlink-directory:before { content:"\F0B1"; }

.octicon-file-symlink-file:before { content:"\F0B0"; }

.octicon-file-text:before { content:"\F011"; }

.octicon-file-zip:before { content:"\F013"; }

.octicon-file:before { content:"\F102"; }

.octicon-flame:before { content:"\F0D2"; }

.octicon-fold:before { content:"\F0CC"; }

.octicon-gear:before { content:"\F02F"; }

.octicon-gift:before { content:"\F042"; }

.octicon-gist-secret:before { content:"\F08C"; }

.octicon-gist:before { content:"\F00E"; }

.octicon-git-branch:before { content:"\F020"; }

.octicon-git-commit:before { content:"\F01F"; }

.octicon-git-compare:before { content:"\F0AC"; }

.octicon-git-merge:before { content:"\F023"; }

.octicon-git-pull-request:before { content:"\F009"; }

.octicon-globe:before { content:"\F0B6"; }

.octicon-grabber:before { content:"\F103"; }

.octicon-graph:before { content:"\F043"; }

.octicon-heart:before { content:"\2665"; }

.octicon-history:before { content:"\F07E"; }

.octicon-home:before { content:"\F08D"; }

.octicon-horizontal-rule:before { content:"\F070"; }

.octicon-hubot:before { content:"\F09D"; }

.octicon-inbox:before { content:"\F0CF"; }

.octicon-info:before { content:"\F059"; }

.octicon-issue-closed:before { content:"\F028"; }

.octicon-issue-opened:before { content:"\F026"; }

.octicon-issue-reopened:before { content:"\F027"; }

.octicon-italic:before { content:"\F0E4"; }

.octicon-jersey:before { content:"\F019"; }

.octicon-key:before { content:"\F049"; }

.octicon-keyboard:before { content:"\F00D"; }

.octicon-law:before { content:"\F0D8"; }

.octicon-light-bulb:before { content:"\F000"; }

.octicon-link-external:before { content:"\F07F"; }

.octicon-link:before { content:"\F05C"; }

.octicon-list-ordered:before { content:"\F062"; }

.octicon-list-unordered:before { content:"\F061"; }

.octicon-location:before { content:"\F060"; }

.octicon-lock:before { content:"\F06A"; }

.octicon-logo-gist:before { content:"\F0AD"; }

.octicon-logo-github:before { content:"\F092"; }

.octicon-mail-read:before { content:"\F03C"; }

.octicon-mail-reply:before { content:"\F051"; }

.octicon-mail:before { content:"\F03B"; }

.octicon-mark-github:before { content:"\F00A"; }

.octicon-markdown:before { content:"\F0C9"; }

.octicon-megaphone:before { content:"\F077"; }

.octicon-mention:before { content:"\F0BE"; }

.octicon-milestone:before { content:"\F075"; }

.octicon-mirror:before { content:"\F024"; }

.octicon-mortar-board:before { content:"\F0D7"; }

.octicon-mute:before { content:"\F080"; }

.octicon-no-newline:before { content:"\F09C"; }

.octicon-octoface:before { content:"\F008"; }

.octicon-organization:before { content:"\F037"; }

.octicon-package:before { content:"\F0C4"; }

.octicon-paintcan:before { content:"\F0D1"; }

.octicon-pencil:before { content:"\F058"; }

.octicon-person:before { content:"\F018"; }

.octicon-pin:before { content:"\F041"; }

.octicon-plug:before { content:"\F0D4"; }

.octicon-plus-small:before { content:"\F104"; }

.octicon-plus:before { content:"\F05D"; }

.octicon-primitive-dot:before { content:"\F052"; }

.octicon-primitive-square:before { content:"\F053"; }

.octicon-pulse:before { content:"\F085"; }

.octicon-question:before { content:"\F02C"; }

.octicon-quote:before { content:"\F063"; }

.octicon-radio-tower:before { content:"\F030"; }

.octicon-reply:before { content:"\F105"; }

.octicon-repo-clone:before { content:"\F04C"; }

.octicon-repo-force-push:before { content:"\F04A"; }

.octicon-repo-forked:before { content:"\F002"; }

.octicon-repo-pull:before { content:"\F006"; }

.octicon-repo-push:before { content:"\F005"; }

.octicon-repo:before { content:"\F001"; }

.octicon-rocket:before { content:"\F033"; }

.octicon-rss:before { content:"\F034"; }

.octicon-ruby:before { content:"\F047"; }

.octicon-search:before { content:"\F02E"; }

.octicon-server:before { content:"\F097"; }

.octicon-settings:before { content:"\F07C"; }

.octicon-shield:before { content:"\F0E1"; }

.octicon-sign-in:before { content:"\F036"; }

.octicon-sign-out:before { content:"\F032"; }

.octicon-smiley:before { content:"\F0E7"; }

.octicon-squirrel:before { content:"\F0B2"; }

.octicon-star:before { content:"\F02A"; }

.octicon-stop:before { content:"\F08F"; }

.octicon-sync:before { content:"\F087"; }

.octicon-tag:before { content:"\F015"; }

.octicon-tasklist:before { content:"\F0E5"; }

.octicon-telescope:before { content:"\F088"; }

.octicon-terminal:before { content:"\F0C8"; }

.octicon-text-size:before { content:"\F0E3"; }

.octicon-three-bars:before { content:"\F05E"; }

.octicon-thumbsdown:before { content:"\F0DB"; }

.octicon-thumbsup:before { content:"\F0DA"; }

.octicon-tools:before { content:"\F031"; }

.octicon-trashcan:before { content:"\F0D0"; }

.octicon-triangle-down:before { content:"\F05B"; }

.octicon-triangle-left:before { content:"\F044"; }

.octicon-triangle-right:before { content:"\F05A"; }

.octicon-triangle-up:before { content:"\F0AA"; }

.octicon-unfold:before { content:"\F039"; }

.octicon-unmute:before { content:"\F0BA"; }

.octicon-unverified:before { content:"\F0E8"; }

.octicon-verified:before { content:"\F0E6"; }

.octicon-versions:before { content:"\F064"; }

.octicon-watch:before { content:"\F0E0"; }

.octicon-x:before { content:"\F081"; }

.octicon-zap:before { content:"\26A1"; }


.spin-octicon {
  -webkit-animation: spin-octicon 2s infinite linear;
          animation: spin-octicon 2s infinite linear;
}
@-webkit-keyframes spin-octicon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes spin-octicon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin '),
    local('Roboto-Thin'),
    url(/static/media/roboto-latin-100.987b8457.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100.e9dbbe8a.woff) format('woff'); /* Modern Browsers */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin italic'),
    local('Roboto-Thinitalic'),
    url(/static/media/roboto-latin-100italic.6232f43d.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100italic.d704bb3d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light '),
    local('Roboto-Light'),
    url(/static/media/roboto-latin-300.55536c8e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300.a1471d1d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light italic'),
    local('Roboto-Lightitalic'),
    url(/static/media/roboto-latin-300italic.d69924b9.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300italic.210a7c78.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular '),
    local('Roboto-Regular'),
    url(/static/media/roboto-latin-400.5d4aeb4e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400.bafb105b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular italic'),
    local('Roboto-Regularitalic'),
    url(/static/media/roboto-latin-400italic.d8bcbe72.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400italic.9680d5a0.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium '),
    local('Roboto-Medium'),
    url(/static/media/roboto-latin-500.28546717.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500.de8b7431.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium italic'),
    local('Roboto-Mediumitalic'),
    url(/static/media/roboto-latin-500italic.510dec37.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500italic.ffcc050b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold '),
    local('Roboto-Bold'),
    url(/static/media/roboto-latin-700.037d8304.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700.cf6613d1.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold italic'),
    local('Roboto-Bolditalic'),
    url(/static/media/roboto-latin-700italic.010c1aee.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700italic.846d1890.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black '),
    local('Roboto-Black'),
    url(/static/media/roboto-latin-900.19b7a0ad.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900.8c2ade50.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black italic'),
    local('Roboto-Blackitalic'),
    url(/static/media/roboto-latin-900italic.7b770d6c.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900italic.bc833e72.woff) format('woff'); /* Modern Browsers */
}


